import { useUnit } from 'effector-react'

import { store } from './store'
import * as actions from './actions'

export function useModalTimer() {
  const { showModal } = useUnit(store)

  return {
    showModal,
    ...actions
  }
}
