import { useEffect } from 'react'

import { BrowserRouter, useLocation } from 'react-router-dom'

import Routes from './routes'
import AppProviders from './AppProviders'
import { getCookieValue } from 'helpers/cookies'
import moment from 'moment'
import { handleOpenModal } from 'modules/timer/actions'
import { LAST_REFRESH, TIME_TO_REFRESH_TOKEN } from 'services/apiConfig'

import { handleSignIn } from 'modules/auth/actions'
import { decryptData } from 'helpers/decryptData'

setInterval(async () => {
  const now = new Date()
  const lastRefresh = getCookieValue(LAST_REFRESH)

  const email = getCookieValue('email') || ''

  const decryptedEmail = decryptData(email)

  const token = localStorage.getItem('key') || ''
  const isLogged = getCookieValue('isLogged') || ''

  const bytesToken = decryptData(token)

  if (moment(now).diff(lastRefresh, 'minutes') >= TIME_TO_REFRESH_TOKEN) {
    if (isLogged === 'true') {
      await handleSignIn({ email: decryptedEmail || '', password: bytesToken })
    } else {
      handleOpenModal()
    }
  }
}, 60000)

function ScrollToTopOnMount() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return null
}

function App() {
  return (
    <AppProviders>
      <BrowserRouter>
        <ScrollToTopOnMount />
        <Routes />
      </BrowserRouter>
    </AppProviders>
  )
}

export default App
