import CryptoJS from 'crypto-js'

export const decryptData = (encryptedData: string): any => {
  try {
    const secretKey: string = process.env.REACT_APP_NAMESPACE_NAME ?? ''
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey)
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8)
    return JSON.parse(decryptedData) // Retorna o dado como objeto/array original
  } catch (error) {
    console.error('Error during decryption:', error)
    return null
  }
}
